body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(240, 240, 240);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ocultar barra de rolagem horizontal e garantir barra de rolagem vertical */
html,
body {
  overflow-x: hidden; /* Oculta a barra de rolagem horizontal */
  height: 100%; /* Define a altura como 100% para garantir que o overflow funcione corretamente */
  margin: 0; /* Remove margens padrão */
}

body {
  scrollbar-gutter: stable; /* Sempre reserva espaço para a barra de rolagem */
  overflow-y: auto; /* Mantém o overflow-y como auto */
}

/* Inicialmente, torna a barra de rolagem invisível */
body::-webkit-scrollbar {
  width: 0;
  height: 10vh;
}

/* Exibe a barra de rolagem ao interagir */
body.show-scroll::-webkit-scrollbar {
  width: 5px;
}

body.show-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}

body.show-scroll::-webkit-scrollbar-track {
  background-color: blue;
}

@media (max-width: 820px) {
  body.show-scroll::-webkit-scrollbar {
    width: 0px;
  }
}
