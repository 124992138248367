* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/*Nav Bar*/

.navBar {
  display: flex;
  justify-content: space-between;
  padding: 0 1vw;
  height: 90px;
  position: fixed;
  top: 0; /* Adiciona o topo para garantir que o elemento esteja visível */
  width: 100%; /* Garante que a barra de navegação ocupe toda a largura */
  z-index: 1000; /* Garante que a barra de navegação fique acima de outros elementos */
  background-color: white; /* Adiciona uma cor de fundo para garantir que o conteúdo abaixo não apareça através da barra */
}

.spaceNavBar {
  margin-top: 90px;
}

.logoPrincipal {
  width: auto;
  height: 80%;
  border-radius: 0;
  position: relative;
  top: 10%;
  right: 0.5vw;
}

.buttons {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  margin-top: 50px;
  font-size: 3vh;
}

/*Menu*/

/* MainMenuDiv */
/* MainMenuDiv */
.main-menu-div {
  background: linear-gradient(to bottom, #004c99, #005eb8, #007bff, #004b72);
  width: 100vw;
  height: 300px;
  display: flex;
  justify-content: center; /* Centraliza a central-div */
  align-items: center;
  position: relative; /* Torna o contêiner pai para posicionamento absoluto das imagens */
}

/* LShapeDiv */
.imgL1,
.imgL2 {
  width: 17.5vw;
  height: 80%;
  position: absolute; /* Altera para posicionamento absoluto */
}

.imgL1 {
  top: 5px;
  left: -1vw; /* Posiciona a imagem no canto superior esquerdo */
  transform: scaleY(-1);
}

.imgL2 {
  bottom: 5px;
  right: -1vw; /* Posiciona a imagem no canto inferior direito */
  transform: scaleX(-1);
}

/* CentralDiv */
.central-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1; /* Garante que a central-div fique acima das imagens */
  text-align: center;
}

/* ButtomDiv */
.buttom-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
}

.buttom-div a {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

/* MainMenuH1 */
.main-menu-h1 {
  color: aliceblue;
  font-family: 'Roboto', sans-serif;
  max-width: 99vw;
}

/* TextBlue */
.text-blue {
  color: black;
  text-shadow: 1px 1px 1px whitesmoke, -1px -1px 1px whitesmoke,
    -1px 1px 1px whitesmoke, 1px -1px 1px whitesmoke;
}

/* BlueBorderButton */
.blue-border-button {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  border: 2px solid white;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
}

/* BlueBackgroundButton */
.blue-background-button {
  display: inline-block;
  padding: 10px 20px;
  color: #0066c2;
  border: 2px solid whitesmoke;
  background-color: whitesmoke;
  text-decoration: none;
  text-align: center;
  transition: color 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
}

.blue-background-button:hover {
  background-color: white;
  color: #007bff;
}

/* Media Queries */
@media (max-width: 1024px) {
  .buttons {
    font-size: 20px;
  }

  .imgL1,
  .imgL2 {
    height: 60%;
  }

  .imgL2 {
    margin-top: 115px;
  }
  .main-menu-h1 {
    font-size: 28px;
  }

  .blue-border-button,
  .blue-background-button {
    font-size: 16px;
  }
}

@media (max-width: 798px) {
  .buttons {
    font-size: 2.3vh;
    margin-right: 2vw;
  }

  .main-menu-h1 {
    font-size: 25px;
  }

  .blue-border-button,
  .blue-background-button {
    padding: 8px 16px;
    font-size: 15px;
  }
}

@media (max-width: 550px) {
  .main-menu-h1 {
    font-size: 23px;
  }

  .imgL1,
  .imgL2 {
    width: 25vw;
    height: 55%;
  }

  .blue-border-button,
  .blue-background-button {
    padding: 8px 16px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .buttons {
    font-size: 2vh;
  }

  .buttom-div {
    margin-top: 4%;
  }
}

@media (max-width: 480px) {
  .main-menu-h1 {
    font-size: 20px;
  }
  .imgL1,
  .imgL2 {
    height: 30%;
  }
  .blue-border-button,
  .blue-background-button {
    padding: 4px 8px;
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  .main-menu-h1 {
    font-size: 18px;
  }
  .imgL1,
  .imgL2 {
    height: 30%;
  }
  .blue-border-button,
  .blue-background-button {
    padding: 4px 8px;
    font-size: 11px;
  }
}

@media (max-width: 400px) and (max-height: 640px) {
  .blue-border-button,
  .blue-background-button {
    padding: 4px 8px;
    font-size: 10px;
  }
  .main-menu-h1 {
    font-size: 16px;
  }
}

/*Menu hamburguer*/

.toogleMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  z-index: 1000; /* Para garantir que o menu esteja acima dos outros elementos */
}

.menu-toggle .bar {
  width: 50px;
  height: 8px;
  background-color: #1a85e8;
  margin: 3px 0;
  transition: 0.3s;
  position: relative;
  right: 1vw;
}

.menu-toggle .bar.open:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-toggle .bar.open:nth-child(2) {
  opacity: 0;
}

.menu-toggle .bar.open:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.buttons.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 35px;
  right: 0;
  background-color: rgb(224, 214, 214); /* Cor de fundo do menu */
  padding: 10px;
  border-radius: 5px;
}

/* Specific media query for iPads */
@media (min-width: 800px) and (max-width: 1100px) and (min-height: 1000px) and (max-height: 1400px) {
  .main-menu-h1 {
    font-size: 2.6vh; /* Adjust this value to reduce font size */
  }
  .buttom-div {
    margin-top: 1.5vh;
  }
  .buttons {
    display: none; /* Esconde o menu padrão em telas pequenas */
  }

  .buttons.active {
    display: flex; /* Mostra o menu quando o botão hamburguer é clicado */
  }

  .menu-toggle {
    display: flex; /* Mostra o botão hamburguer em telas pequenas */
  }
}

@media (max-width: 820px) {
  .buttons {
    display: none; /* Esconde o menu padrão em telas pequenas */
    row-gap: 2px;
  }

  .buttons.active {
    display: flex; /* Mostra o menu quando o botão hamburguer é clicado */
  }

  .menu-toggle {
    display: flex; /* Mostra o botão hamburguer em telas pequenas */
  }
}

@media (max-width: 550px) {
  .menu-toggle .bar {
    width: 35px;
    height: 5px;
  }
}

@media (max-height: 600px) {
  .menu-toggle .bar {
    width: 35px;
    height: 4px;
  }
}

/*Clientes*/

.clients {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
}

/* Adiciona deslocamento quando .show-scroll é aplicado */
.clients.show-scroll {
  position: relative;
  left: 2.5px; /* Desloca o elemento 5px para a direita */
}

.sectionTitle,
.sectionTitle2,
.sectionTitle3 {
  padding: 5px 0px 5px 0px;
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
  color: whitesmoke;
  border: 2px solid black;
  border-radius: 20px;
  margin-bottom: 25px;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  width: 40vw;
  text-align: center;
}

.sectionTitle2 {
  padding: 5px 0px 5px 0px;
}

.sectionTitle3 {
  padding: 5px 0px 5px 0px;
}

.logosClients {
  background: linear-gradient(to bottom, #0055aa, #005eb8, #007bff, #005682);
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 10vh 0px 10vh 0px;
  flex-wrap: wrap;
}

.logosClients img {
  width: 17vw;
  height: auto;
  border-radius: 50px;
  margin: -4vh 0;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
}

@media (max-width: 1120px) {
  .logosClients img {
    width: 19vw;
    border-radius: 25px;
  }
  .sectionTitle,
  .sectionTitle2,
  .sectionTitle3 {
    font-size: 28px;
    border-radius: 30px;
    width: 50vw;
  }

  .sectionTitle2 {
    padding: 5px 0px 5px 0px;
  }
}

@media (max-width: 820px) {
  .logosClients {
    padding-left: 10vw;
    padding-right: 10vw;
    gap: 10vw;
  }

  .logosClients img {
    width: 27vw;
    border-radius: 25px;
    margin: -1vh 0;
    margin-left: 0vw;
    margin-right: 0vw;
  }
  .sectionTitle,
  .sectionTitle2,
  .sectionTitle3 {
    font-size: 25px;
    border-radius: 20px;
  }

  #logoTop1,
  #logoTop2 {
    margin-top: -5vh;
  }

  #logoBottom1,
  #logoBottom2 {
    margin-bottom: -5vh;
  }

  .clients.show-scroll {
    position: relative;
    left: 0px; /* Desloca o elemento 5px para a direita */
  }
}

@media (max-width: 600px) {
  .sectionTitle,
  .sectionTitle2,
  .sectionTitle3 {
    width: 70vw;
  }

  .logosClients {
    padding-left: 10vw;
    padding-right: 10vw;
    gap: 10vw;
  }

  .logosClients img {
    width: 31vw;
    border-radius: 25px;
    margin: -2vh 0;
  }
}

@media (max-width: 550px) {
  .sectionTitle,
  .sectionTitle2,
  .sectionTitle3 {
    font-size: 23px;
  }
}

@media (max-width: 500px) {
  .sectionTitle,
  .sectionTitle2,
  .sectionTitle3 {
    margin-bottom: 23px;
  }
}

@media (max-width: 450px) {
  .logosClients {
    padding-left: 10vw;
    padding-right: 10vw;
    gap: 10vw;
  }

  .logosClients img {
    width: 35vw;
    border-radius: 20px;
    margin: -0.5vh 0;
  }

  .sectionTitle {
    padding: 5px 0px 5px 0px;
    font-size: 18px;
  }

  .sectionTitle2 {
    padding: 5px 0px 5px 0px;
    font-size: 18px;
  }

  .sectionTitle3 {
    padding: 5px 0px 5px 0px;
    font-size: 18px;
  }

  .sectionTitle,
  .sectionTitle2,
  .sectionTitle3 {
    width: 72vw;
  }
}

@media (max-width: 450px) and (max-height: 600px) {
  .sectionTitle {
    padding: 5px 40px 5px 40px;
    font-size: 17px;
  }

  .sectionTitle2 {
    padding: 5px 57.1px 5px 57.1px;
    font-size: 17px;
  }

  .sectionTitle3 {
    padding: 5px 48.55px 5px 48.55px;
    font-size: 17px;
  }

  .sectionTitle,
  .sectionTitle2,
  .sectionTitle3 {
    width: 85vw;
  }
}

/* Services */

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
}

#verServico {
  position: relative;
  bottom: 75px;
  z-index: -100;
}

/* Adiciona deslocamento quando .show-scroll é aplicado */
.services.show-scroll {
  position: relative;
  left: 2.5px; /* Desloca o elemento 5px para a direita */
}

.allServices {
  background: linear-gradient(to bottom, #0055aa, #005eb8, #007bff, #005682);
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 5vh 5vw 5vh 5vw;
  flex-wrap: wrap;
}

.service {
  padding: 5px 5px 5px 5px;
  background-color: whitesmoke;
  border: 10px solid black;
  width: 40vw;
  height: 40vh;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 2.5vw 2.5vw 2.5vw 2.5vw;
}

.titleService {
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service h3 {
  font-family: 'Roboto', sans-serif;
  padding-bottom: 5px;
  width: calc(100% + 10px);
}

.service p {
  font-family: 'Roboto', sans-serif;
}

.service h6 {
  font-size: 16px;
}

.priceAndTime {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 10px);
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  height: 7vh;
  align-items: center;
}

.price {
  width: 50%;
  height: 100%;
  border-right: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.time {
  width: 50%;
  height: 100%;
  border-left: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.priceAndTime p {
  font-size: 16px;
}

.descripition {
  border-bottom: 2px solid black;
  width: calc(100% + 10px);
  height: 16vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.descripition h6 {
  margin-top: 5px;
}

.descripition p {
  font-size: 14px;
  margin-top: 5px;
  padding-left: 1px;
  padding-right: 1px;
}

.buttonService {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 9vh;
}

.blue-button {
  padding: 10px 20px;
  color: black;
  border: 2px solid black;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  position: relative;
  top: 2px;
}

.blue-button:hover {
  color: rgba(0, 0, 0, 0.75);
  border: 2px solid rgba(0, 0, 0, 0.75);
}
@media (max-width: 1217px) {
  .descripition p {
    font-size: 14px;
  }
  .priceAndTime p {
    font-size: 16px;
  }
  .blue-button {
    padding: 8px 16px;
    font-size: 16px;
  }

  .descripition {
    height: 19vh;
  }

  .buttonService {
    height: 6vh;
  }
}

@media (max-width: 1000px) {
  .descripition {
    height: 19vh;
  }

  .buttonService {
    height: 6vh;
  }

  .blue-button {
    padding: 7px 14px;
    font-size: 15px;
  }
}

@media (max-width: 820px) {
  .services.show-scroll {
    position: relative;
    left: 0px; /* Desloca o elemento 5px para a direita */
  }
  .allServices {
    padding: 3vh 5vw 3vh 5vw;
    gap: 5vw;
  }
  .service {
    width: 80vw;
    height: 35vh;
    border: 4px solid black;
    margin: 0vw 0vw 0vw 0vw;
  }
  .blue-button {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .service h3 {
    font-size: 18px;
  }
  .service h6 {
    font-size: 16px;
  }
  .service {
    width: 85vw;
    height: 45vh;
  }
  .descripition {
    height: 25vh;
  }
  .descripition p {
    font-size: 14px;
  }
  .buttonService {
    position: relative;
    top: 5px;
  }
  .clients,
  .services {
    margin-top: 20px;
  }
}

@media (max-width: 400px) {
  .service {
    width: 90vw;
  }
  .priceAndTime p {
    font-size: 16px;
  }
  .descripition p {
    font-size: 13px;
  }
  .buttonService {
    position: relative;
    top: 2px;
  }
}

@media (max-width: 350px) {
  .buttonService {
    position: relative;
    top: 3.65px;
  }
}

@media (max-height: 950px) {
  .service h3 {
    font-size: 20px;
  }
  .service h6 {
    font-size: 18px;
  }
  .priceAndTime p {
    font-size: 18px;
  }
  .descripition p {
    font-size: 16px;
  }
  .blue-button {
    font-size: 16px;
  }
}

@media (max-width: 350px) and (max-height: 950px) and (min-height: 810px) {
  .service h3 {
    font-size: 20px;
  }
  .service h6 {
    font-size: 18px;
  }
  .priceAndTime p {
    font-size: 18px;
  }
  .descripition p {
    font-size: 15px;
  }
  .blue-button {
    font-size: 15px;
  }
}

@media (max-height: 810px) {
  .service h3 {
    font-size: 18px;
  }
  .service h6 {
    font-size: 16px;
  }
  .priceAndTime p {
    font-size: 16px;
  }
  .descripition p {
    font-size: 14px;
  }
  .blue-button {
    font-size: 14px;
  }
}

@media (max-height: 690px) {
  .service h3 {
    font-size: 17px;
  }
  .service h6 {
    font-size: 15px;
  }
  .priceAndTime p {
    font-size: 15px;
  }
  .descripition p {
    font-size: 13.7px;
  }
  .blue-button {
    font-size: 13px;
  }
}

@media (max-height: 600px) {
  .service h3 {
    font-size: 17px;
  }
  .service h6 {
    font-size: 15px;
  }
  .priceAndTime p {
    font-size: 14px;
  }
  .descripition p {
    font-size: 13px;
  }
  .blue-button {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .service h3 {
    font-size: 16px;
  }
  .service h6 {
    font-size: 14px;
  }
  .priceAndTime p {
    font-size: 13px;
  }
  .descripition p {
    font-size: 11px;
  }
  .blue-button {
    font-size: 11px;
  }
}

@media (max-height: 510px) {
  .service h3 {
    font-size: 15px;
  }
  .service h6 {
    font-size: 13px;
  }
  .priceAndTime p {
    font-size: 12px;
  }
  .descripition p {
    font-size: 10.3px;
  }
  .blue-button {
    font-size: 10.3px;
  }
}

@media (max-width: 600px) and (max-height: 780px) {
  .buttonService {
    position: relative;
    top: 2px;
  }
}

@media (max-width: 600px) and (max-height: 720px) {
  .buttonService {
    position: relative;
    top: 1.5px;
  }
}

@media (max-width: 600px) and (max-height: 630px) {
  .buttonService {
    position: relative;
    top: 1.1px;
  }
}

@media (max-width: 600px) and (max-height: 600px) {
  .buttonService {
    position: relative;
    top: 1px;
  }
}

@media (max-width: 400px) and (max-height: 600px) {
  .blue-button {
    position: relative;
    top: 1px;
  }
}

@media (min-width: 700px) and (max-width: 1150px) and (min-height: 950px) and (max-height: 1400px) {
  .service {
    width: 85vw;
    height: 35vh;
    border: 4px solid black;
  }
  .allServices {
    padding: 5vh 5vw 5vh 5vw;
  }
  .service h3 {
    font-size: 25px;
  }
  .service h6 {
    font-size: 20px;
  }
  .priceAndTime p {
    font-size: 20px;
  }
  .descripition p {
    font-size: 18px;
  }
  .blue-button {
    font-size: 18px;
  }
}

@media (min-width: 810px) and (max-width: 1150px) and (min-height: 950px) and (max-height: 1400px) {
  .service {
    width: 75vw;
    height: 30vh;
    border: 4px solid black;
  }
  .allServices {
    padding: 5vh 5vw 5vh 5vw;
  }
  .service h3 {
    font-size: 25px;
  }
  .service h6 {
    font-size: 20px;
  }
  .priceAndTime p {
    font-size: 20px;
  }
  .descripition p {
    font-size: 18px;
  }
  .blue-button {
    font-size: 18px;
  }
}

@media (min-width: 900px) and (max-height: 700px) {
  .service h3 {
    font-size: 17px;
  }
  .service h6 {
    font-size: 15px;
  }
  .priceAndTime p {
    font-size: 14px;
  }
  .descripition p {
    font-size: 11.5px;
  }
  .blue-button {
    font-size: 13px;
  }
}
/*Formulario*/

#solicitarServico {
  position: relative;
  bottom: 75px;
  z-index: -100;
}

.formDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
  width: 100vw;
}

.formDiv.show-scroll {
  position: relative;
  left: 2.5px; /* Desloca o elemento 5px para a direita */
}

/* Estilos gerais para o formulário */
form {
  padding: 30px 10px 30px 10px;
  width: 100vw;
  color: white; /* Texto branco */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formMain {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, #0055aa, #005eb8, #007bff, #005682);
}

form h2 {
  text-align: center;
  color: white;
}

form label {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  font-family: 'Roboto', sans-serif;
}

form input,
form select,
form textarea {
  width: 84.7vw;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white; /* Fundo dos inputs branco */
  color: black; /* Texto preto nos inputs */
  font-size: 14px;
  border: 2px solid black;
  font-family: 'Roboto', sans-serif;
}

form textarea {
  height: 170px;
}

form input:focus,
form select:focus,
form textarea:focus {
  outline: none;
  border-color: #0056b3; /* Cor do foco azul-escuro */
}

form button {
  width: 40vw;
  padding: 12px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 17.5px;
  border: 1px solid whitesmoke;
}

form button:hover {
  background-color: #333; /* Hover para botão */
}

form p {
  color: red;
  font-size: 18px;
  margin-top: 10px;
}

.error {
  background-color: #ff1111;
  color: whitesmoke;
  width: 60vw;
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2px solid white;
  border-radius: 25px;
  text-align: center;
  position: relative;
  top: 5px;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-6px);
  }
  50% {
    transform: translateX(6px);
  }
  75% {
    transform: translateX(-6px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shake2 {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(3px);
  }
  75% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

.shake {
  animation: shake 0.35s ease;
}

.shake2 {
  animation: shake2 0.35s ease;
}

/* Responsividade */

@media (max-width: 1120px) {
  form button {
    width: 50vw;
  }
  .error {
    font-size: 15px;
  }
}

@media (max-width: 820px) {
  form input,
  form select,
  form textarea {
    width: 80vw;
  }

  .error {
    width: 80vw;
  }

  form button {
    width: 55vw;
    margin-top: 15px;
  }
}

@media (max-width: 500px) {
  form {
    padding: 20px 10px 20px 10px;
  }

  form p {
    font-size: 14px;
  }

  form input,
  form select,
  form textarea {
    width: 84.5vw;
  }

  .error {
    width: 84.5vw;
    font-size: 13px;
    top: 0px;
  }

  form h2 {
    font-size: 20px;
  }

  form label {
    font-size: 14px;
  }

  form input,
  form select,
  form textarea {
    font-size: 12px;
  }

  form button {
    width: 60vw;
    font-size: 12px;
    padding: 10px;
    margin-top: 3.5px;
  }
}

@media (max-width: 400px) {
  form input,
  form select,
  form textarea {
    width: 89.5vw;
  }
  .error {
    width: 89.5vw;
    font-size: 11.5px;
  }
}

/*Sobre nós*/

.aboutStart {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.aboutTitleDiv {
  display: flex;
  flex-direction: column;
}

.aboutTitle {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);

  padding: 20px 10px 20px 10px;
  width: 100vw;
  text-align: center;
}

.aboutBox1,
.aboutBox2 {
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);

  width: 65vw;
  min-height: 130px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5vh;
  padding: 20px 10px 20px 10px;
}

.aboutBox1 {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.aboutBox2 {
  margin-left: 35vw;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.aboutBox1 h1,
.aboutBox2 h1 {
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  margin-bottom: 2px;
  width: auto;
}

.aboutLine {
  width: 100%;
  background-color: white;
  border: 0.5px solid black;
  height: 4px;
  margin-bottom: 10px;
}

.aboutBox1 p,
.aboutBox2 p {
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
}

@media (max-width: 1000px) {
  .aboutBox1,
  .aboutBox2 {
    width: 80vw;
  }
  .aboutBox2 {
    margin-left: 20vw;
  }
}

@media (max-width: 820px) {
  .aboutBox1,
  .aboutBox2 {
    width: 85vw;
  }
  .aboutBox2 {
    margin-left: 15vw;
  }
}

@media (max-width: 700px) {
  .aboutBox1 h1,
  .aboutBox2 h1 {
    font-size: 20px;
  }

  .aboutBox1 p,
  .aboutBox2 p {
    font-size: 14px;
  }
  .aboutTitle {
    font-size: 25px;
  }
}

@media (max-width: 500px) {
  .aboutBox1 h1,
  .aboutBox2 h1 {
    font-size: 18px;
  }

  .aboutLine {
    border: 0.4px solid black;
    height: 3px;
  }

  .aboutBox1 p,
  .aboutBox2 p {
    font-size: 12px;
  }

  .aboutTitle {
    font-size: 22px;
  }
}

@media (max-width: 400px) {
  .aboutBox1,
  .aboutBox2 {
    width: 95vw;
  }
  .aboutBox2 {
    margin-left: 5vw;
  }
}

@media (max-width: 350px) {
  .aboutBox1 p,
  .aboutBox2 p {
    font-size: 10px;
  }

  .aboutTitle {
    font-size: 20px;
  }
}

/*Membros*/

.members {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.memberTitleDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.memberTitle {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);

  padding: 20px 10px 20px 10px;
  width: 100vw;
  text-align: center;
}

.lucas,
.joao {
  width: 65vw;
  padding: 20px 10px 20px 10px;
  background: linear-gradient(to bottom, #0066cc, #005eb8, #007bff, #006b99);
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2vw;
  margin-bottom: 40px;
}

.joao {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.lucas {
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  margin-left: 35vw;
}

.imgMember {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imgMember img {
  width: 13vw;
  height: auto;
  border-radius: 50%;
}

.aboutMember h1 {
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  margin-bottom: 2px;
}

.aboutMember p {
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
}

@media (max-width: 1200px) {
  .imgMember img {
    width: 16vw;
  }
}

@media (max-width: 1000px) {
  .lucas,
  .joao {
    width: 80vw;
  }
  .lucas {
    margin-left: 20vw;
  }
  .imgMember img {
    width: 19vw;
  }
}

@media (max-width: 820px) {
  .lucas,
  .joao {
    width: 85vw;
  }
  .lucas {
    margin-left: 15vw;
  }
  .imgMember img {
    width: 22vw;
  }
}

@media (max-width: 700px) {
  .imgMember img {
    width: 25vw;
  }
  .aboutMember h1 {
    font-size: 20px;
  }
  .aboutMember p {
    font-size: 14px;
  }
  .memberTitle {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .imgMember img {
    width: 32vw;
  }
}

@media (max-width: 500px) {
  .imgMember img {
    width: 35vw;
  }
  .aboutMember h1 {
    font-size: 18px;
  }
  .aboutMember p {
    font-size: 12px;
  }
  .memberTitle {
    font-size: 22px;
  }
}

@media (max-width: 400px) {
  .imgMember img {
    width: 35vw;
  }
  .lucas,
  .joao {
    width: 95vw;
  }
  .lucas {
    margin-left: 5vw;
  }
}

@media (max-width: 350px) {
  .aboutMember p {
    font-size: 10px;
  }
  .memberTitle {
    font-size: 20px;
  }
}

/*Sucesso*/
.success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(to bottom, #004c99, #005eb8, #007bff, #004b72);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 150px);
  gap: 20px;
}

.success h1,
.success h2 {
  color: white;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
  font-family: 'Roboto', sans-serif;
}

.backToHome {
  text-decoration: none;
  width: 20vw;
  height: 6.5vh;
  background-color: rgba(255, 255, 255, 0.95);
  border: 5px solid black;
  color: black;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-family: 'Source Code Pro', monospace;
}

@media (max-width: 1100px) {
  .backToHome {
    width: 30vw;
  }
}

@media (max-width: 820px) {
  .success h1 {
    font-size: 25px;
  }
  .success h2 {
    font-size: 20px;
  }
  .backToHome {
    width: 40vw;
  }
}

@media (max-width: 600px) {
  .success h1 {
    font-size: 21px;
  }
  .success h2 {
    font-size: 16px;
  }
  .backToHome {
    font-size: 14px;
    width: 45vw;
  }
}

@media (max-width: 430px) {
  .success h1 {
    font-size: 19px;
  }
  .success h2 {
    font-size: 14px;
  }
  .backToHome {
    width: 60vw;
  }
}

@media (max-width: 390px) {
  .success h1 {
    font-size: 17px;
  }
  .success h2 {
    font-size: 13px;
  }
}
/*Rodape*/

.footer {
  width: 100vw;
  background-color: white;
  color: black;
  text-align: center;
  height: 60px;
  font-size: 14px;
  z-index: 1000; /* Garante que o rodapé esteja acima de outros elementos se necessário */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .footer {
    font-size: 11px;
  }
}

/*Login*/

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(to bottom, #004c99, #005eb8, #007bff, #004b72);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 150px);
}

.login h1 {
  margin-bottom: 50px;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black,
    1px -1px 1px black;
}

/*Requests*/

.requests {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100 - 150px);
}

.requests h1 {
  text-align: center;
}

.requests.show-scroll {
  position: relative;
  left: 2.5px; /* Desloca o elemento 5px para a direita */
}

.order {
  width: 80vw; /* Padrão para telas maiores que 620px */
  background-color: #f0f0f0;
  padding: 20px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  list-style: none;
}

.order h3 {
  color: #1111ff;
  margin-bottom: 10px;
}

.order p {
  margin: 5px 0;
  color: #333;
}

.noorders {
  text-align: center;
}

.noorders p {
  color: #ff1111;
}

.noorders a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #1111ff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
}

.noorders a:hover {
  background-color: #0000cc;
}

/* Estilos responsivos */
@media (max-width: 620px) {
  .order {
    width: 90vw; /* 90% da largura em telas menores que 620px */
  }
}
